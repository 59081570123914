<template>
    <div class="modal fade" id="print-label-model" tabindex="-1" style="display: none;" aria-hidden="true" ref="printLabelModel">
      <div class="modal-dialog modal-dialog-centered modal-xl opacity-0">
        <div class="modal-content">
          <div class="modal-body">
            <div class="box position-relative" v-if="printLabelUserInfo">
              <div class="main_hd" v-if="printLabelUserInfo.last_name || printLabelUserInfo.first_name">
                {{ printLabelUserInfo.last_name.toUpperCase() }} {{ printLabelUserInfo.first_name.toUpperCase() }}
              </div>
              <div class="d-flex align-items-center" v-if="hasHealthInfo">
                <span class="bold_title m-0">HEALTH #</span>
                <span class="bold_line">|</span>
                <span class="con_detail">{{ printLabelUserInfo.health_insurance_no }} {{ printLabelUserInfo.health_card_ver }}</span> 
                <span class="bold_title">GENDER</span>
                <span class="bold_line">|</span>
                <span class="con_detail">
                  <template v-if="printLabelUserInfo.gender === 'M'">Male</template>
                  <template v-else-if="printLabelUserInfo.gender === 'F'">Female</template>
                  <template v-else-if="printLabelUserInfo.gender === 'O'">Other</template>
                  <template v-else-if="printLabelUserInfo.gender === 'T'">Trans</template>
                </span> 
                <span class="bold_title">DOB</span>
                <span class="bold_line">|</span>
                <span class="con_detail">{{ printLabelUserInfo.dob }}</span>
              </div>
              <div class="mt-5">
                <div class="con_detail mb-1">{{ printLabelUserInfo.residential_address }}</div>
                <div class="con_detail mb-1">
                  {{ formattedLocation }}
                </div>
                <div class="bold_title m-0" v-if="printLabelUserInfo.cell_phone"> TEL <span class="con_detail">{{ printLabelUserInfo.cell_phone }}</span></div>
              </div>
            </div>
            <img src="/images/gray_logo.svg" class="img-fluid watermark_logo">
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "@/scripts/axios.js";
  import $ from "jquery";

  export default {
    props: {
      patientId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        printLabelUserInfo: null,
      };
    },
    computed: {
      hasHealthInfo() {
        return this.printLabelUserInfo?.health_insurance_no || this.printLabelUserInfo?.health_card_ver || this.printLabelUserInfo?.gender || this.printLabelUserInfo?.dob;
      },
      formattedLocation() {
        if (this.printLabelUserInfo) {
          const city = this.printLabelUserInfo.residential_city;
          const province = this.printLabelUserInfo.residential_province_name;
          const zip = this.printLabelUserInfo.residential_zip;
          return `${city}, ${province} ${zip}`;
        }
        return '';
      }
    },
    methods: {
      async openModal() {
        try {
          const response = await axios.post("patient/retrieve", { id: this.patientId });
          if (response.status === 200) {
            this.printLabelUserInfo = response.data.data;
            window.$("#print-label-model").modal("show");

            const modal = window.$("#print-label-model");
            modal.modal("show");

            // Remove any existing event listeners to avoid duplicate handlers
            modal.off('shown.bs.modal');

            // Wait for the modal to be fully shown before retrieving dimensions
            modal.on('shown.bs.modal', () => {
                this.loadPrintLabel();
            });

        }
        } catch (error) {
          console.error("API error:", error);
        }
      },
      async loadPrintLabel() {
        try {
          const modalHeight = $("#print-label-model").find('.modal-body').outerHeight();
          const modalWidth = $("#print-label-model").find('.modal-body').outerWidth();
          const url = `${process.env.VUE_APP_API_BASE_URL}/patient/${this.patientId}/label/${modalHeight}/${modalWidth}`;
  
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            }
          });
          const blob = await response.blob();
          window.$("#print-label-model").modal("hide");
          const newUrl = URL.createObjectURL(blob);
          window.open(newUrl, '_blank');
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
  };
  </script>